/*
 * @Author: liyu
 * @Date: 2022-04-27 10:58:31
 * @LastEditTime: 2022-04-27 18:48:48
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Entertainment/ArticleProduct/index.tsx
 */
import React from 'react'
import Button from 'components/Base/Button'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade } from 'swiper'
import classnames from 'classnames'

SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade])

import 'swiper/swiper-bundle.css'
import * as styles from './index.module.less'

export interface ProductPorps {
  dataTitle: string
  imgUrl: string
  imgUrlMb: string
  dataMsg: string
  dataDsc: string
  btnHref: string
  btnText: string
}
interface ArticleProduct {
  dataSource?: ProductPorps[]
  title?: string,
  className?: string,
  style?: React.CSSProperties
}
const ArticleProduct: React.FC<ArticleProduct> = ({ title, dataSource, className, style = {} }) => {
  return (
    <section
      style={style}
      className={classnames(styles.productContainer, className)}>
      <div className="product-content">
        <h2 className="product-title">{title}</h2>
        <ul className="product-card">
          {dataSource?.map((item) => (
            <li key={item.dataTitle}>
              <div>
                <img src={item.imgUrl} alt="" />
              </div>
              <div className="card-content">
                <div className="card-msg">{item.dataMsg}</div>
                <div className="card-title">{item.dataTitle}</div>
                <div className="card-dsc">{item.dataDsc}</div>
                <Button btnType="text" href={item.btnHref} target="_blank">
                  <div className="card-more">{item.btnText}</div>
                </Button>
              </div>
            </li>
          ))}
        </ul>
        <div className="product-swiper-mb">
          <Swiper
            autoplay={true}
            allowTouchMove={true}
            autoHeight={true}
            pagination={{ type: 'bullets' }}
            speed={800}
            effect={'fade'}
          >
            {dataSource?.map((item) => (
              <SwiperSlide key={item.dataTitle}>
                <Button btnType="text" href={item.btnHref} target="_blank">
                  <div className="swier-content">
                    <div className="swiper-img">
                      <img src={item.imgUrlMb} alt="" />
                    </div>

                    <div className="card-content-mb">
                      <div className="card-msg-mb">{item.dataMsg}</div>
                      <div className="card-title-mb">{item.dataTitle}</div>
                      <div className="card-dsc-mb">{item.dataDsc}</div>

                      <div className="card-more-mb">{item.btnText}</div>
                    </div>
                  </div>
                  <div className="swiper-bottom"></div>
                </Button>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default ArticleProduct
