import React from 'react'
import classnames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay } from 'swiper'

SwiperCore.use([Pagination, Autoplay])

import { Languages } from 'i18n/config'
import Layout from 'layouts/zh'
import Seo from 'components/seo'
import PageBottomCard from 'components/PageBottomCard'
import Card from 'components/ManageUnit/Card'
import CardList from 'components/ManageUnit/CardList'
import EntertainmentButton from 'components/Entertainment/EntertainmentButton'
import ScanCodeEntertainmentButton from 'components/Entertainment/ScanCodeEntertainmentButton'
import SectionSubstance, { SectionSubstanceBody } from 'components/SectionSubstance'
import Panorama from 'components/Panorama'
import PartnerCard from 'components/PartnerCard'
import ArticleProduct from 'components/Entertainment/ArticleProduct'
import CargoCard from 'components/CargoCard'
import ArticleFooter from 'components/ArticleFooter'
import ShadowCard from 'components/ManageUnit/ShadowCard'
import SectionProgram from 'components/Game/SectionProgram'
import ShadowCardList from 'components/ManageUnit/ShadowCardList'
import EntertainmentBanner from 'components/Entertainment/EntertainmentBanner'
import Tab from 'components/ManageUnit/Tab'

// styles
import * as styles from './index.module.less'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'

const EcommerceB2C: React.FC = (props) => {
  const demoUrl = ''
  const paradeUrl = ''
  const { ecommerceB2CYaml: datas } = useStaticQuery(graphql`
    query ecommerceB2CQuery {
      ecommerceB2CYaml {
        title
        description
        keywords
        SaTitle
        banner {
          title
          titleMb
          desc
          buttonText
          buttonHref
          bg
          bgMb
          banner_icon
          banner_icon_mb
        }
        section1 {
          title
          subTitle
          qrcodeButtonText
          qrcodeUrl
          qrcodeDesc
          tabs {
            title
            descList
            buttonText
            buttonHref
            cover
          }
        }
        section2 {
          subTitle
          title
          widgetL
          qrcodeButtonText
          qrcodeUrl
          qrcodeDesc
          sectionCardList {
            title
            desc
            cover
          }
        }
        section4 {
          title
          subTitle
          buttonText
          bg
          bgMb
          qrcode
          cover
          widgetL
          widgetR
          btnTextTwo
          btnTextTwoUrl
        }
        section5 {
          title
          subTitle
          bg
          bgMb
          card
          cardMb
          logoList
          buttonText
          qrcode
          qrcodeDesc
        }
        section6 {
          title
          sectionCardList {
            title
            cate
            linkText
            link
            cover
            coverMb
          }
        }
        section7 {
          title
          sectionCardList {
            title
            desc
            linkText
            cate
            link
            cover
          }
        }
        tabs {
          name
          url
        }
        footer {
          title
          subTitle
          demoText
          demoHref
          orderText
          orderHref
        }
        bottomBg
        bottomBgMb
      }
    }
  `)

  return (
    <Layout {...props}>
      <EcommerceB2CState demoUrl={demoUrl} paradeUrl={paradeUrl} data={datas} />
    </Layout>
  )
}

interface EcommerceB2CStateProps {
  demoUrl?: string
  paradeUrl?: string
  data: {
    sectionCardList: Array<{
      title: string
      desc: string
      cover: string
    }>
    [propName: string]: any
  }
  lang?: Languages
}
export const EcommerceB2CState: React.FC<EcommerceB2CStateProps> = (props) => {
  // text-primary
  // text-sd-black
  const { demoUrl, paradeUrl, data, lang } = props

  return (
    <>
      <Seo title={data.title} description={data.description} keywords={data.keywords} saTitle={data.SaTitle} />
      <main className={classnames(styles.EcommerceB2C, 'lg:pt-[64px] pt-[4rem]')}>
        <EntertainmentBanner
          bgPC={data.banner.bg}
          bgMB={data.banner.bgMb}
          bannerIcon={data.banner.banner_icon}
          bannerIconMB={data.banner.banner_icon_mb}
          bannerTitle={data.banner.title}
          bannerTitleMobile={data.banner.titleMb}
          bannerIconClassName="w-[180px] lg:w-[588px] mr-0 lg:mr-auto"
          bannerDesc={<span className="text-sd-black">{data.banner.desc}</span>}
          bannerButtonText={data.banner.buttonText}
          bannerButtonHref={data.banner.buttonHref}
          sectionTwoTitleLeftInMobile
          sectionTwoTitle={data.section1.title}
          sectionTwoDesc={data.section1.subTitle}
          slotBannerButton={
            <div className={classnames('banner-btn mt-[3rem] lg:mt-[30px] text-center lg:text-left')}>
              <EntertainmentButton
                className="hidden lg:inline-block"
                btnType="primary"
                round
                href={data.banner.buttonHref}
                target="_blank"
              >
                {data.banner.buttonText}
              </EntertainmentButton>

              <EntertainmentButton
                className="lg:hidden"
                btnType="default"
                round
                href={data.banner.buttonHref}
                target="_blank"
              >
                {data.banner.buttonText}
              </EntertainmentButton>
            </div>
          }
          slotSectionTwoMobileButton={
            <EntertainmentButton
              className="inline-block"
              btnType="primary"
              round
              href={data.banner.buttonHref}
              target="_blank"
            >
              {data.banner.buttonText}
            </EntertainmentButton>
          }
          qrcodeButtonText={data.section1.qrcodeButtonText}
          qrcodeDesc={data.section1.qrcodeDesc}
          qrcodeUrl={data.section1.qrcodeUrl}
          dataSource={data.section1.tabs.map((item) => ({
            dataTitle: item.title,
            descList: item.descList,
            buttonText: item.buttonText,
            buttonHref: item.buttonHref,
            imgUrl: item.cover,
          }))}
          slotTab={
            <Tab
              className="relative"
              textColor="#E17F8B"
              shadow={styles.TabShadow}
              bgColor={styles.TabBgColor}
              list={data.tabs.map((tab) => ({
                ...tab,
                isActive: tab.name === 'B2C 电商',
              }))}
            />
          }
        />

        <SectionSubstance
          className="relative"
          titleTextAlignMb="left"
          title={data.section2.title}
          titleMb={data.section2.title}
          descText={data.section2.subTitle}
          descTextFlag={false}
        >
          <img className="hidden lg:block absolute top-0 left-0 w-[125px] h-auto" src={data.section2.widgetL} alt="" />
          <SectionSubstanceBody>
            <CardList
              autoplay
              titleColor="#1F2D3D"
              coverHeight={200}
              yGap={20}
              xGap={45}
              mXGap={40}
              mYGap={0}
              datas={data.section2.sectionCardList}
            />

            <div className="text-center">
              <ScanCodeConsultButton
                buttonType="primary"
                shape="circle"
                buttonText={data.section2.qrcodeButtonText}
                qrcode={data.section2.qrcodeUrl}
                imgBottomText={data.section2.qrcodeDesc}
              />
            </div>
          </SectionSubstanceBody>
        </SectionSubstance>

        {/* section4 */}
        <SectionSubstance
          titleTextAlignMb="left"
          className="!pt-[100px] lg:!pt-[120px] !pb-[80px] lg:!pb-[100px]"
          title={data.section4.title}
          titleMb={data.section4.title}
          descText={data.section4.subTitle}
          style={{
            backgroundImage: `url(${data.section4.bg})`,
            '@media (minWidth: 1024px)': {
              backgroundImage: `url(${data.section4.bgMb})`,
            },
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <SectionSubstanceBody>
            <Panorama
              btnText={data.section4.buttonText}
              btnCode={data.section4.qrcode}
              imgUrl={data.section4.cover}
              btnTextTwo={data.section4.btnTextTwo}
              btnTextTwoUrl={data.section4.btnTextTwoUrl}
            />
          </SectionSubstanceBody>
        </SectionSubstance>

        {/* 来自客户的声音 */}
        <SectionSubstance
          titleTextAlignMb="left"
          className="relative"
          title={data.section5.title}
          titleMb={data.section5.title}
          descText={data.section5.subTitle}
        >
          <img
            className="hidden lg:block absolute w-[155px] h-auto top-[-117px] -z-10"
            src={data.section4.widgetL}
            alt=""
          />
          <img
            className="hidden lg:block absolute w-[149px] h-auto bottom-0 right-0"
            src={data.section4.widgetR}
            alt=""
          />
          <SectionSubstanceBody>
            <div className="lg:w-[996px] mt-[30px] lg:mx-auto lg:mt-[69px] ">
              <img src={data.section5.card} className="hidden lg:block" alt="" />
              <img
                style={{
                  boxShadow: '0px 2px 20px 0px rgba(153, 169, 191, 0.18)',
                }}
                src={data.section5.cardMb}
                className="block lg:hidden"
                alt=""
              />
            </div>

            <div className="mt-[39px] mb-[19px]">
              <PartnerCard
                dataSource={data.section5.logoList.map((logo) => ({
                  imgUrl: logo,
                }))}
              />
            </div>

            <div className="text-center lg:block">
              <ScanCodeEntertainmentButton
                buttonText={data.section5.buttonText}
                qrcode={data.section5.qrcode}
                imgBottomText={data.section5.qrcodeDesc}
              />
            </div>
          </SectionSubstanceBody>
        </SectionSubstance>

        <SectionSubstance
          style={{
            paddingBottom: 0,
            backgroundImage: `url(${data.bottomBg})`,
            '@media (minWidth: 1024px)': {
              backgroundImage: `url(${data.bottomBgMb})`,
            },
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <SectionSubstanceBody>
            {/* 更多干货 */}
            <div className="pb-[26px] lg:pb-[85px] -mx-8 px-8 lg:px-0 lg:mx-0">
              <h1 className="text-[24px] lg:text-[40px] leading-[1.5] pt-[40px] pb-[20px] lg:py-0 text-sd-black lg:mb-[40px]">
                {data.section6.title}
              </h1>
              <CargoCard
                dataSource={data.section6.sectionCardList.map((item) => ({
                  dataTitle: item.title,
                  dataMsg: item.cate,
                  btnHref: item.link,
                  btnText: item.linkText,
                  imgUrl: item?.cover,
                  imgUrlMb: item?.coverMb,
                }))}
              />
            </div>

            <ArticleProduct
              className="!mb-0 lg:!mb-[118px]"
              style={{
                backgroundColor: 'transparent',
              }}
              title={data.section7.title}
              dataSource={data.section7.sectionCardList.map((card) => ({
                dataTitle: card.title,
                imgUrl: card.cover,
                imgUrlMb: card.cover,
                dataMsg: card.cate,
                dataDsc: card.desc,
                btnHref: card.link,
                btnText: card.linkText,
              }))}
            />
          </SectionSubstanceBody>
        </SectionSubstance>
        <ArticleFooter
          className={styles.Footer}
          title={data.footer.title}
          gameDsc={data.footer.subTitle}
          btnText={data.footer.demoText}
          btnHref={data.footer.demoHref}
          orderText={data.footer.orderText}
          orderHref={data.footer.orderHref}
        />
      </main>
    </>
  )
}

export default EcommerceB2C
